body {
  background-color: #f3f7f9 !important;
}
.sidebar-color {
  background-color: white !important;
}

.nav-link-without-icon {
  padding-left: 3rem;
}

.search-box-container {
  position: relative;
  height: 40px;
}

.search-box-icon {
  position: absolute;
  top: 11px;
  left: 11px;
}

.noArrows::-webkit-outer-spin-button,
.noArrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.search-box-input {
  height: 100%;
  width: 250px;
  border: 1px solid #50d6c1;
  padding-left: 50px;
}

.header-username {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8a93a8;
  border: 1px solid #50d6c1;
}
/* .navContainer {
  display: flex;
  height: 50px;
  width: 95%;
  border-radius: 10px;
  margin-left: 10px;
  height: 40px;
  align-items: center;
} */
.nav-link-without-icon {
  padding-left: 3rem;
  color: #5b779a;
}
.navlink-icon {
  width: 25;
  height: 22;
  margin-right: 1.5rem;
}
.sidebar-nav .nav-group.show {
  background: #eff2f6 !important;
  border-radius: 15px;
  width: 90%;
  margin-left: 12px;
}
.header-circle-container {
  display: flex;
  gap: 10px;
}
.header-circle {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.sidebar-nav .nav-group {
  position: relative;
  // transition: background 0.15s ease-in-out;
  width: 90%;
  margin-left: 12px;
}
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 20px;
}
.sidebar-nav .nav-link {
  display: flex;
  flex: 1 1;
  align-items: center;
  width: 100%;
  padding: 1;
  color: var(--cui-sidebar-nav-link-color, rgb(86, 106, 127));
  text-decoration: none;
  white-space: nowrap;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: var(--cui-sidebar-nav-link-bg, transparent);

  // transition: background 0.15s ease, color 0.15s ease;
}
.sidebar-nav .nav-link:hover {
  color: var(--cui-sidebar-nav-link-color,rgb(86, 106, 127));
  background-color: #eff2f6;
}
.sidebar-nav .nav-link.active {
  color: #56c4d0 !important;
  font-weight: bold;
  border-left: 3px solid;
  width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background: #eff2f6;
}
.sidebar-nav .nav-group.show > .nav-group-toggle::after {
  transform: rotate(90deg);
}
.sidebar-nav .nav-group-toggle::after {
  display: block;
  flex: 0 1 8px;
  height: 8px;
  margin-bottom: 13px;
  content: "⮞";
  transition: transform 0.15s;
  color: #5b779a;
}
.sidebar-nav .nav-group-toggle {
  cursor: pointer;
  font-size: 18px;
}
.nav-link-without-icon {
  padding-left: 1rem;
}
.nav-link span{
  color:  #5b779a;
}
.table-button {
  width: 100px;
  height: 37px;
  border: none;
  color: #fff;
  margin-right: 10px;
  text-align: left;
}

.add-button {
  width: 170px;
  height: 37px;
  border: none;
  color: #fff;
  margin-right: 10px;
}

.screen-header {
  text-align: right;
}

.header-search-box-icon {
  position: absolute;
  top: 11px;
  right: 215px;
}

.header-search-box-input {
  height: 100%;
  width: 250px;
  border: 1px solid #50d6c1;
  padding-left: 50px;
  margin-left: auto;
}

.add-title {
  color: #76797b;
  font-size: 20px;
  font-weight: 500;
}

.add-input-label {
  color: #76797b;
  font-weight: 500;
}

.add-input {
  border: 1px solid #50d6c1;
}

.attach-personal-image {
  width: 130px;
  height: 130px;
  cursor: pointer;
}

.add-modal-footer {
  text-align: center;
}

.add-modal-footer-button {
  width: 105px;
  height: 37px;
  border: none;
  border-radius: 10px;
  margin-right: 15px;
}

.personal-image-container > input {
  display: none;
}

.patient-info-label,
.patient-history-label,
.patient-history-data {
  color: #76797b;
  display: block;
  font-weight: 700;
  font-size: 18px;
}

.patient-history-label {
  font-weight: 500;
}

.patient-info-data {
  color: #76797b;
  display: block;
  font-size: 18px;
}

.patient-history-data {
  font-weight: 400;
}

.patient-history-title {
  color: #8298bc;
  display: block;
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0 0 10px;
}

.patient-history-email {
  display: block;
  color: #000;
  font-size: 18px;
}

.patient-history-tabs-item {
  color: #8298bc;
  cursor: pointer;
}

.disable-tab {
  background-color: #c4c4c473;
  color: #918f8f;
}

.patient-history-table-header {
  background-color: #f3f7f9;
}

.patient-history-table-header-data {
  color: #8298bc !important;
}

.patient-history-table-row-data {
  font-weight: 400 !important;
}

.demo-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  height: 400px !important;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}

.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.editable-textbox {
  height: 360px;
  overflow: scroll;
}

.payment-history-title {
  font-size: 20px;
}

.search-payment-history {
  background-color: #f1f1f1;
}

.search-payment-history-text {
  background-color: #eeeeee;
}

.patient-payment-info-title {
  display: block;
  font-size: 15px;
}

.patient-payment-info-name {
  color: #50d6c1;
}

.patient-payment-info-address,
.patient-payment-info-icon {
  color: #ccc;
}

.patient-payment-info-contact:hover {
  background-color: #fff;
}

.patient-payment-info-contact:hover .patient-payment-info-icon {
  color: #00b581;
}

.bg-payment-card {
  background-color: #777777;
}

.add-service-textarea {
  height: 98px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hover:hover {
  background-color: #0000ff;
  color: #fff;
}

.new-payment-unorderlist {
  height: 250px;
  overflow-y: auto;
}

.sideModal-details {
  width: 300px;
  height: 500px;
  position: absolute;
  right: 0;
  top: 25%;
  z-index: 1;
}

.toast {
  position: fixed;
  z-index: 1;
}

.medicine-section {
  background-color: #f1f2f7;
}

.fc-time-grid .fc-slats td {
  height: 4.5em !important;
}

.appointment-instructions-box {
  width: 40px;
  height: 33px;
}


iframe{
  overflow:hidden;
  border:0;
  align-self: center;
  position:absolute;
  top:15%;
  left:0;
  width:100%;
  height:100%;
}

.AddButton  {
  left: 85%;
  position:absolute;


}
.DoctorButton{
  margin: 3px;
  padding: 5px 10px;
  color: white;
  border: none;
}


.form-label{
  font-weight: 700;
}


// zindex select a doctor list
.css-b62m3t-container {
  z-index: 10;
}

// reduce fullcalendar row height
.fc-time-grid .fc-slats td {
  height: auto !important;
}

.fc-title {
  color: #0d120f!important; /* Set color for titles with class 'event-red' */
}

.fc-axis span{
  color: #0d120f!important;
}
.fc-event-container .fc-content .fc-time{
  color: transparent!important;
}

// .css-g1d714-ValueContainer div{
//   background-color: #6e6e6e;
// }
